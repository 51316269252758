import useStore from 'hooks/useStore'
import dayjs from 'dayjs'
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui'
import { FormatterFn } from '@devexpress/dx-react-scheduler'
import { colorList } from 'components/AccountGroupFilter/GroupForm'

const CalendarCardContent = (props: Appointments.AppointmentContentProps) => {
  const { timeFormat } = useStore()

  const formatDate: FormatterFn = (date) => {
    return dayjs(date).format(timeFormat)
  }

  // Determines the background color for the appointment card based on group/category
  const backgroundColor = () => {
    // Check if group has direct color metadata
    if (props?.data?.group?.metadata?.color) {
      return props.data.group.metadata.color
    }

    return colorList[0]
  }

  return (
    <Appointments.AppointmentContent
      {...props}
      formatDate={formatDate}
      style={{ backgroundColor: backgroundColor(), height: '100%' }}
    ></Appointments.AppointmentContent>
  )
}
export default CalendarCardContent
