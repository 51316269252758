import { TaskUser, ScheduledTask, TaskItem } from './Tasks'

type RoutineDefinitionTask = {
  routineTask: {
    id: string
    title: string
    scheduledTask: ScheduledTask
  }
}

export enum CurriculumStatusId {
  SKIPPED = 0,
  NOT_STARTED = 1,
  ACTIVE = 2,
  COMPLETED = 3,
}
export type CurriculumTaskStatusOptions =
  | 0
  | 1
  | 2
  | 3
  | 'Completed'
  | 'Skipped'
  | 'Not Started'
  | 'Active'

export type CurriculumGroup = {
  id: number
  title: string
  metadata: {
    icon: string
    color: string
  } | {}
}

export type NextCurriculum = {
  id: string
  title: string
}

export type CurriculumFinishActionOptions = 'stop' | 'next' | 'repeat'
export type CurriculumModeOptions = 'Active' | 'Template'
export type CurriculumStatusOptions =
  | 0
  | 1
  | 2
  | 3
  | 'Not Started'
  | 'Active'
  | 'Paused'
  | 'Done'

export type Curriculum = {
  id: string
  title: string
  user: TaskUser
  assignee?: TaskUser
  group: CurriculumGroup
  description: string
  mode: CurriculumModeOptions
  createdAt: string
  finishAction: CurriculumFinishActionOptions
  status: CurriculumStatusOptions
  progressRatio: number
  nextCurriculum?: NextCurriculum
  routineDefinition?: Partial<ScheduledTask>
  routineDefinitionTask?: RoutineDefinitionTask[]
  tasks: CurriculumTaskItem[]
  pendingAssignee?: { email: string; status: CurriculumDelegationStatuses }
  hasBeenAssigned: boolean
}

export type CurriculumTaskItem = {
  id: number
  sortingCode: string
  templateTask: TaskItem
  status: CurriculumTaskStatusOptions
  task: TaskItem
}

export type CurriculumFilterOptions = 'yours' | 'delegated' | 'library'
export type CurriculumDelegationStatuses = 'Expired' | 'Pending' | 'Refused'

export type UpdateCurriculumProps = Omit<
  Curriculum,
  'group' | 'nextCurriculum'
> & {
  group: number
  nextCurriculum: string
}
