import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import TrapFocus from '@mui/material/Unstable_TrapFocus'
import useStore from 'hooks/useStore'
import useSubscription from 'hooks/useSubscription'
import React, { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { SubscriptionPackage } from 'utils/subscription'
import CloseButton from './CloseButton'

type State = {
  url: string
  visible: boolean
  closable: boolean
  currentStatus: string
  package: string
}

type Action = {
  type: 'all' | 'templates' | 'curriculum' | 'none'
  payload: State
}

const initialState: State = {
  url: '',
  closable: false,
  visible: false,
  currentStatus: '',
  package: '',
}

const licencingActionReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'all':
      return {
        ...state,
        url: action.payload.url,
        visible: action.payload.visible,
        closable: action.payload.closable,
        currentStatus: action.payload.currentStatus,
        package: action.payload.package,
      }
    case 'templates':
      return {
        ...state,
        url: action.payload.url,
        visible: action.payload.visible,
        closable: action.payload.closable,
        currentStatus: action.payload.currentStatus,
        package: action.payload.package,
      }
    case 'curriculum':
      return {
        ...state,
        url: action.payload.url,
        visible: action.payload.visible,
        closable: action.payload.closable,
        currentStatus: action.payload.currentStatus,
        package: action.payload.package,
      }
    default:
      return state
  }
}

const PayWall = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const [licensingAction, dispatch] = useReducer(
    licencingActionReducer,
    initialState,
  )

  const getUserConfig = useStore((state) => state.getUserConfig)
  const getSubscriptionPackage = useStore(
    (state) => state.getSubscriptionPackage,
  )
  const getWPLoginUrl = useStore((state) => state.getWPLoginUrl)
  const logout = useStore((state) => state.logout)
  const clearSettingsSlice = useStore((state) => state.clearSettingsSlice)
  const resetSubscriptionStatus = useStore(
    (state) => state.resetSubscriptionStatus,
  )
  const { billingUrl, updateBillingUrl, updateSubscriptionPackage } =
    useSubscription((state) => state)

  const [isProvisioned, setIsProvisioned] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    getUserConfig().then((config) => {
      if (config.isProvisioned === 1) {
        setIsProvisioned(true)
      } else {
        setIsProvisioned(false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isProvisioned) {
      getSubscriptionPackage().then((data) => {
        const { currentStatus, package: pkg } = data
        const snake_case_pkg = pkg
          .toLowerCase() // Converts the string to lowercase
          .replace(/\s+/g, '_') // Replaces spaces with _

        updateSubscriptionPackage(snake_case_pkg)

        const hasFullPermissions =
          (snake_case_pkg === SubscriptionPackage.FULL_14_DAYS_TRIAL ||
            snake_case_pkg === SubscriptionPackage.TEAMS_LICENSE) &&
          currentStatus === 'active'

        if (hasFullPermissions) {
          return
        }

        const showSubscriptionLicensing =
          snake_case_pkg === SubscriptionPackage.FREE_LICENSE ||
          currentStatus === 'cancelled' ||
          currentStatus === 'expired'

        const showTemplateLicensing =
          snake_case_pkg === SubscriptionPackage.STANDARD_LICENSE &&
          pathname === '/templates'

        const showCurriculumLicensing =
          snake_case_pkg === SubscriptionPackage.STANDARD_LICENSE &&
          pathname === '/curriculum'

        if (showSubscriptionLicensing) {
          dispatch({
            type: 'all',
            payload: {
              url: 'https://tepintasks.com/licensing-subscription-options',
              visible: true,
              closable: false,
              currentStatus,
              package: snake_case_pkg,
            },
          })
          return
        }

        if (showTemplateLicensing) {
          dispatch({
            type: 'templates',
            payload: {
              url: 'https://tepintasks.com/template-license-options/',
              visible: true,
              closable: true,
              currentStatus,
              package: snake_case_pkg,
            },
          })
          return
        }

        if (showCurriculumLicensing) {
          dispatch({
            type: 'curriculum',
            payload: {
              url: 'https://tepintasks.com/curriculum-license-options/',
              visible: true,
              closable: true,
              currentStatus,
              package: snake_case_pkg,
            },
          })
          return
        }

        if (currentStatus === 'on-hold') {
          dispatch({
            type: 'all',
            payload: {
              url: 'https://tepintasks.com/license-on-hold/',
              visible: true,
              closable: true,
              currentStatus,
              package: snake_case_pkg,
            },
          })
          return
        }
      })
    }
  }, [
    getSubscriptionPackage,
    pathname,
    updateSubscriptionPackage,
    isProvisioned,
  ])

  useEffect(() => {
    getWPLoginUrl().then((data) => {
      if (data) {
        updateBillingUrl(data)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeBanner = () => {
    if (
      licensingAction.currentStatus === 'on-hold' &&
      licensingAction.package === SubscriptionPackage.STANDARD_LICENSE &&
      pathname === '/templates'
    ) {
      dispatch({ type: 'all', payload: { ...licensingAction, visible: false } })
      navigate('/')
      return
    }
    if (
      licensingAction.currentStatus === 'on-hold' &&
      licensingAction.package === SubscriptionPackage.STANDARD_LICENSE &&
      pathname === '/curriculum'
    ) {
      dispatch({ type: 'all', payload: { ...licensingAction, visible: false } })
      navigate('/')
      return
    }
    if (
      licensingAction.package === SubscriptionPackage.STANDARD_LICENSE &&
      pathname === '/templates'
    ) {
      dispatch({ type: 'all', payload: { ...licensingAction, visible: false } })
      navigate('/')
      return
    }
    if (
      licensingAction.package === SubscriptionPackage.STANDARD_LICENSE &&
      pathname === '/curriculum'
    ) {
      dispatch({ type: 'all', payload: { ...licensingAction, visible: false } })
      navigate('/')
      return
    }
    if (licensingAction.closable === true) {
      dispatch({ type: 'all', payload: { ...licensingAction, visible: false } })
      return
    }
  }

  const onLogout = () => {
    clearSettingsSlice()
    resetSubscriptionStatus()
    logout()
  }

  return (
    <React.Fragment>
      <Backdrop
        open={licensingAction.visible || false}
        sx={{ zIndex: 1040 }}
        onClick={closeBanner}
      />
      <TrapFocus open disableAutoFocus disableEnforceFocus>
        <Fade appear={false} in={licensingAction.visible || false}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              m: 0,
              p: 0,
              borderWidth: 0,
              borderTopWidth: 1,
              zIndex: 1050,
              height: 'calc(45vh)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
              }}
            >
              <iframe
                title="Paywall"
                src={licensingAction?.url || ''}
                loading="eager"
                style={{
                  flexGrow: 1,
                  border: 'none',
                  margin: 0,
                  padding: 0,
                  backgroundColor: 'transparent',
                }}
              />
            </Box>
            <CloseButton
              onClick={closeBanner}
              visible={licensingAction.closable || false}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                position: 'absolute',
                bottom: 40,
                right: 80,
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  window.open(billingUrl, '_blank')
                }}
              >
                {t('settings.billing-and-licensing.button-text')}
              </Button>
              <Link component="button" variant="subtitle1" onClick={onLogout}>
                {t('settings.billing-and-licensing.sign-out')}
              </Link>
            </Box>
          </Paper>
        </Fade>
      </TrapFocus>
    </React.Fragment>
  )
}

export default PayWall
