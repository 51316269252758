import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import Alert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import Link from '@mui/material/Link'
import useStore from 'hooks/useStore'
import { Trans } from 'react-i18next'

const LicensingBanner = () => {
  const subscriptionStatus = useStore((state) => state.subscriptionStatus)
  const resetSubscriptionStatus = useStore(
    (state) => state.resetSubscriptionStatus,
  )
  const getUserConfig = useStore((state) => state.getUserConfig)

  const { licensingEvent, licensingStatus, licensingEntity } =
    subscriptionStatus

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    getUserConfig().then((config) => {
      if (config.isProvisioned === 1 && licensingStatus) {
        setIsOpen(true)
      } else {
        setIsOpen(false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensingStatus])

  const closeAlert = () => {
    setIsOpen(false)
    resetSubscriptionStatus()
  }

  if (isOpen) {
    return (
      <Alert
        icon={false}
        variant="filled"
        sx={{
          backgroundColor: `var(--licensing-${licensingStatus}-color)`,
        }}
        action={
          <IconButton
            aria-label="delete"
            size="small"
            onClick={closeAlert}
            sx={{
              display: licensingStatus === 'exceeded' ? 'none' : 'inline-flex',
            }}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        }
      >
        <Trans
          i18nKey={`subscription.${licensingEvent}.${licensingStatus}`}
          default="You have reached or exceeded your current subscription limits. Please upgrade your subscription."
          ns="validation"
          values={{ entity: licensingEntity }}
          components={[
            <React.Fragment />, // this empty fragment is needed to enable the link
            <Link href="/settings?activeTab=billingLicensing"></Link>,
          ]}
        />
      </Alert>
    )
  }

  return <></>
}

export default LicensingBanner
