import useStore from 'hooks/useStore'
import dayjs from 'dayjs'
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui'
import { FormatterFn } from '@devexpress/dx-react-scheduler'
import { colorList } from 'components/AccountGroupFilter/GroupForm'

const AppointmentCardContent = (
  props: Appointments.AppointmentContentProps,
) => {
  const { groups } = useStore((state) => state.tasks)
  const { categories } = useStore((state) => state)
  const { timeFormat } = useStore()

  const formatDate: FormatterFn = (date) => {
    return dayjs(date).format(timeFormat)
  }

  // Determines the background color for the appointment card based on group/category
  const backgroundColor = () => {
    // Check first if there is a groupCategory
    if (props?.data?.groupCategory && props?.data?.groupCategory !== 'null') {
      const category = categories.find(
        (category) => category.id === Number(props.data.groupCategory),
      )
      return category ? category.metadata.color : colorList[0]
    }

    // Check if group is an array of groups
    if (Array.isArray(props?.data?.group) && props?.data?.group.length > 0) {
      const g = groups?.find(
        (group) => group.id === Number(props.data.group[0].id),
      )
      return g ? g.metadata.color : colorList[0]
    }

    // Check if group has direct color metadata
    if (props?.data?.group?.metadata?.color) {
      return props.data.group.metadata.color
    }

    return colorList[0]
  }

  return (
    <Appointments.AppointmentContent
      {...props}
      formatDate={formatDate}
      style={{ backgroundColor: backgroundColor(), height: '100%' }}
    ></Appointments.AppointmentContent>
  )
}
export default AppointmentCardContent
