import {
  DownOutlined,
  FlagFilled,
  PlusOutlined,
  UpOutlined,
} from '@ant-design/icons'
import { Button, Space, Tooltip } from 'antd'
import DueDateDropdown from 'components/DueDateDropdown/DueDateDropdown'
import RoutineDropdown from 'components/RoutineDropdown'
import SchedulingDropDown from 'components/SchedulingDropdown'
import { UserAvatarWithTooltip } from 'components/UserAvatar'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import 'reactflow/dist/style.css'
import { FlexibleTime } from 'types/FlexibleTime'
import { PendingAssignee, ScheduledTask, TaskAssignees } from 'types/Tasks'
import { TaskActions, TaskFields } from 'utils/permissions'
import IconContainer from '../IconContainer'

export type CardIconsProps = {
  dueDateIconClassName: string
  dueDateTooltip: string
  flexibleTime: FlexibleTime | undefined
  groupIcon: React.ReactNode
  groupName: string
  hasChildren?: boolean
  hasGroup: boolean
  hasPermissionToUpdateField: (field: string, roles?: number[]) => boolean
  hidden: boolean
  iconClassName: string
  iconFlagClassName: string
  isRoutineInstance: boolean
  isSubtaskOfRoutine: boolean
  isUrgent: boolean
  isIssue: boolean
  onAddChildTask: React.MouseEventHandler<HTMLSpanElement> | undefined
  onCollapseExpand: React.MouseEventHandler<HTMLSpanElement>
  onDueDateChanged: (selection?: dayjs.Dayjs | undefined) => void
  onPriorityChanged: React.MouseEventHandler<HTMLSpanElement> | undefined
  onScheduleChanged: (value?: Partial<ScheduledTask> | null) => void
  roles?: number[] | undefined
  routineIconClassName: string
  routineIsOpen: boolean
  schedule?: Partial<ScheduledTask> | null
  scheduledClassName: string
  setFlexibleTime: (f: FlexibleTime | undefined) => void
  setRoutineIsOpen: (nextValue: boolean) => void
  pendingAssignees?: PendingAssignee[]
  taskAssignees?: TaskAssignees[]
}

const CardIcons = (props: CardIconsProps) => {
  const { t } = useTranslation()
  const {
    dueDateIconClassName,
    dueDateTooltip,
    flexibleTime,
    groupIcon,
    groupName,
    hasChildren,
    hasGroup,
    hasPermissionToUpdateField,
    hidden,
    iconClassName,
    iconFlagClassName,
    isRoutineInstance,
    isSubtaskOfRoutine,
    isUrgent,
    isIssue,
    onAddChildTask,
    onCollapseExpand,
    onDueDateChanged,
    onPriorityChanged,
    onScheduleChanged,
    roles,
    routineIconClassName,
    routineIsOpen,
    schedule,
    scheduledClassName,
    setFlexibleTime,
    setRoutineIsOpen,
    pendingAssignees,
    taskAssignees,
  } = props

  return (
    <Space>
      {!isRoutineInstance && !isSubtaskOfRoutine && (
        <IconContainer>
          <DueDateDropdown
            outlined={false}
            iconClassName={dueDateIconClassName}
            onChange={onDueDateChanged}
            tooltip={dueDateTooltip}
            allowClear={true}
            disabled={!hasPermissionToUpdateField(TaskFields.DUE_AT, roles)}
          />
        </IconContainer>
      )}
      {!isSubtaskOfRoutine && !isIssue && (
        <IconContainer>
          <RoutineDropdown
            outlined={false}
            title={t('new-task-form.recurrence.title')}
            isOpen={routineIsOpen}
            setIsOpen={setRoutineIsOpen}
            iconClassName={routineIconClassName}
            onChange={onScheduleChanged}
            value={schedule || undefined}
            setFlexibleTime={setFlexibleTime}
            flexibleTime={flexibleTime}
            disabled={
              !hasPermissionToUpdateField(TaskFields.SCHEDULED_TASK, roles)
            }
            isRoutineInstance={isRoutineInstance}
          />
        </IconContainer>
      )}
      {!isRoutineInstance && !isSubtaskOfRoutine && !isIssue && (
        <IconContainer>
          <SchedulingDropDown
            outlined={false}
            title={t('new-task-form.recurrence.scheduling')}
            setRoutineIsOpen={setRoutineIsOpen}
            iconClassName={scheduledClassName}
            onChange={onScheduleChanged}
            value={schedule || undefined}
            setFlexibleTime={setFlexibleTime}
            disabled={
              !hasPermissionToUpdateField(TaskFields.SCHEDULED_TASK, roles)
            }
          />
        </IconContainer>
      )}
      {!isIssue && (
        <IconContainer>
          <Tooltip
            title={isUrgent ? t('task-node.urgent') : t('task-node.normal')}
          >
            <FlagFilled
              className={iconFlagClassName}
              onClick={(evt) =>
                hasPermissionToUpdateField(TaskFields.FLAG, roles) &&
                onPriorityChanged?.(evt)
              }
              disabled={!hasPermissionToUpdateField(TaskFields.FLAG, roles)}
            />
          </Tooltip>
        </IconContainer>
      )}
      {!isIssue && (
        <IconContainer>
          <Tooltip title={t('task-node.add-subtask')}>
            <Button
              type="text"
              icon={<PlusOutlined className={iconClassName} />}
              onClick={onAddChildTask}
              data-testid="add-child"
              disabled={
                !hasPermissionToUpdateField(TaskActions.ADD_CHILDREN, roles)
              }
            />
          </Tooltip>
        </IconContainer>
      )}
      <IconContainer isAvatarContainer={true}>
        <UserAvatarWithTooltip
          pendingAssignees={pendingAssignees}
          taskAssignees={taskAssignees}
          size={20}
        />
      </IconContainer>
      {hasGroup && (
        <IconContainer>
          <Tooltip title={groupName}>
            <Button type="text" icon={groupIcon} />
          </Tooltip>
        </IconContainer>
      )}
      {hasChildren && (
        <IconContainer>
          <Tooltip
            title={hidden ? t('task-node.expand') : t('task-node.collapse')}
          >
            <div onClick={(e) => e.stopPropagation()}>
              {hidden ? (
                <DownOutlined
                  onClick={onCollapseExpand}
                  className={iconClassName}
                />
              ) : (
                <UpOutlined
                  onClick={onCollapseExpand}
                  className={iconClassName}
                />
              )}
            </div>
          </Tooltip>
        </IconContainer>
      )}
    </Space>
  )
}

export default CardIcons
