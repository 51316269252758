import { nanoid } from 'nanoid'
import type { TaskItem, TaskFormProps, PendingAssignee } from 'types/Tasks'
import {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react'
import useStore from 'hooks/useStore'
import { hasPermission } from 'utils/permissions'

type PendingAssignees = {
  email: string
}

export const TaskEditorModalTabs = Object.freeze({
  TASK_FORM: 'taskForm',
  CUSTOM_FIELDS: 'customFields',
  REPORT: 'report',
  NOTIFICATIONS: 'notifications',
  ATTACHMENTS: 'attachments',
  MESSAGES: 'messages',
  TASK_MEMBERS: 'taskMembers',
  AGENDAS: 'agendas',
})

type InitialValuesType = Partial<TaskItem> & {
  children?: TaskItem[]
  pendingAssignees?: PendingAssignees[]
  isUpdate?: boolean
  isLibraryView?: boolean
  isCustomFieldTemplate?: boolean
  curriculumTaskId?: number
  showChatAtOpening?: boolean
}

type EditorMode = 'create' | 'edit'

interface TaskEditor {
  isOpen: boolean
  isDrawerOpen: boolean
  isCurriculumModalOpen: boolean
  isAssignedCurriculum: boolean
  setIsAssignedCurriculum?: (value: boolean) => void
  initialValues?: InitialValuesType
  editorMode: EditorMode
  setEditorMode: (mode: 'create' | 'edit') => void
  toggleDrawer: () => void
  open: (
    values?: InitialValuesType,
    onClose?: () => void,
    mode?: 'create' | 'edit',
  ) => void
  openCurriculumModal: (
    values?: InitialValuesType,
    onClose?: () => void,
  ) => void
  close: () => void
  closeCurriculumModal: () => void
  reset: () => void
  setInitialValues: (values?: InitialValuesType) => void
  setCurrentValues: (values?: TaskFormProps) => void
  currentValues: TaskFormProps | undefined
  hasUpdatePermissionOnTask: (field: string) => boolean
  initialAssignees: PendingAssignee[]
  setInitialAssignees: (assignees: PendingAssignee[]) => void
  selectedEmails: string[]
  setSelectedEmails: Dispatch<SetStateAction<string[]>>
}

const TaskEditorContext = createContext<TaskEditor>({
  isOpen: false,
  isDrawerOpen: false,
  isAssignedCurriculum: false,
  isCurriculumModalOpen: false,
  initialValues: {},
  editorMode: 'create',
  setEditorMode: () => {},
  toggleDrawer: () => {},
  open: () => {},
  openCurriculumModal: () => {},
  close: () => {},
  closeCurriculumModal: () => {},
  reset: () => {},
  setInitialValues: () => {},
  setCurrentValues: () => {},
  currentValues: undefined,
  hasUpdatePermissionOnTask: () => {
    return false
  },
  initialAssignees: [],
  setInitialAssignees: () => {},
  selectedEmails: [],
  setSelectedEmails: () => {},
})

type ProviderProps = {
  children: JSX.Element
}

export const useTaskEditor = (): TaskEditor => {
  const context = useContext(TaskEditorContext)

  if (context === undefined) {
    throw new Error(`useTaskEditor must be used within a TaskEditorProvider`)
  }
  return context
}

export const TaskEditorProvider = ({ children }: ProviderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAssignedCurriculum, setIsAssignedCurriculum] = useState(false)
  const [onClose, setOnClose] = useState<Function | undefined>(undefined)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isCurriculumModalOpen, setIsCurriculumModalOpen] = useState(false)
  const [initialValues, setInitialValues] = useState<InitialValuesType>()
  const [currentValues, setCurrentValues] = useState<TaskFormProps>()
  const [selectedEmails, setSelectedEmails] = useState<string[]>([])
  const [editorMode, setEditorMode] = useState<'create' | 'edit'>('create')
  const [initialAssignees, setInitialAssignees] = useState<PendingAssignee[]>(
    [],
  )

  const taskRolePermissions = useStore((state) => state.taskRolePermissions)

  const hasPermissionToUpdateField = useCallback(
    (field: string) => {
      return initialValues?.isUpdate
        ? hasPermission(field, initialValues?.roles, taskRolePermissions)
        : true
    },
    [initialValues?.isUpdate, initialValues?.roles, taskRolePermissions],
  )

  const providerValue = useMemo(() => {
    return {
      isOpen,
      isDrawerOpen,
      isCurriculumModalOpen,
      initialValues,
      currentValues,
      selectedEmails,
      open: (values, newOnClose, mode) => {
        if (values) {
          setInitialValues(values)
          setEditorMode(mode || 'edit')
        } else {
          setInitialValues({ id: nanoid(5) })
          setEditorMode('create')
        }
        setIsOpen(true)
        setOnClose(() => newOnClose)
      },
      openCurriculumModal: (values, newOnClose) => {
        if (values) {
          setInitialValues(values)
        } else {
          setInitialValues({ id: nanoid(5) })
        }
        setIsCurriculumModalOpen(true)
        setOnClose(() => newOnClose)
      },
      close: () => {
        setInitialValues(undefined)
        setIsOpen(false)
        onClose?.()
        setOnClose(undefined)
      },
      closeCurriculumModal: () => {
        setInitialValues(undefined)
        setIsCurriculumModalOpen(false)
        onClose?.()
        setOnClose(undefined)
      },
      toggleDrawer: () => {
        setIsDrawerOpen((prevState) => !prevState)
      },
      reset: () => {
        setInitialValues(initialValues)
      },
      setInitialValues,
      setCurrentValues,
      setSelectedEmails,
      hasUpdatePermissionOnTask: hasPermissionToUpdateField,
      editorMode,
      setEditorMode,
      initialAssignees,
      isAssignedCurriculum,
      setIsAssignedCurriculum,
      setInitialAssignees,
    } as TaskEditor
  }, [
    hasPermissionToUpdateField,
    initialValues,
    isCurriculumModalOpen,
    isDrawerOpen,
    isOpen,
    onClose,
    currentValues,
    editorMode,
    selectedEmails,
    initialAssignees,
    setInitialAssignees,
    setIsAssignedCurriculum,
    isAssignedCurriculum,
  ])

  return (
    <TaskEditorContext.Provider value={providerValue}>
      {children}
    </TaskEditorContext.Provider>
  )
}
