import { AxiosResponse } from 'axios'
import { GroupCategory } from 'types/Categories'
import type { StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'

export interface CategoriesState extends ApiState {
  selectedCategory: number
  categories: GroupCategory[]
  createGroupCategory: (category: GroupCategory) => Promise<GroupCategory>
  updateGroupCategory: (group: Partial<GroupCategory>) => Promise<GroupCategory>
  deleteGroupCategory: (id: number) => Promise<void>
  getGroupCategory: () => Promise<GroupCategory[]>
  setCurrentCategory: (category: number) => void
}

export const createCategoriesSlice: StateCreator<
  CategoriesState,
  [['zustand/immer', never]]
> = (set, get) => ({
  selectedCategory: 0,
  categories: [],
  createGroupCategory: async ({
    title,
    description,
    metadata,
    categoryGroups,
  }) => {
    try {
      const response = await get().api!('POST', '/user/group/category', {
        title,
        metadata,
        description,
        categoryGroups,
      })
      if (response?.status === 200 || response?.status === 201) {
        set((prev) => {
          prev.categories = prev.categories.concat(response.data)
        })
      }
      return response?.data
    } catch (error) {
      console.error('An error occurred: ', error)
      throw error
    }
  },
  updateGroupCategory: async (group) => {
    const response = await get().api!(
      'PATCH',
      `/user/group/category/${group.id}`,
      group,
    )
    return response?.data
  },
  deleteGroupCategory: async (id: number) => {
    try {
      const response = await get().api!('DELETE', `/user/group/category/${id}`)

      if (response?.status >= 200 && response?.status < 300) {
        const categories = get().categories
        const index = categories.findIndex((cat) => cat.id === id)
        if (index !== -1) {
          categories.splice(index, 1)
          set((prev) => {
            prev.categories = categories
          })
        }
      }
      return response?.data
    } catch (error) {
      console.error('An error occurred: ', error)
      throw error
    }
  },
  getGroupCategory: async () => {
    const response: AxiosResponse = await get().api!(
      'GET',
      `/user/group/category`,
    )
    const result: GroupCategory[] = response?.data
    if (response.status === 200) {
      set((prev) => {
        prev.categories = result
      })
    }
    return result
  },
  setCurrentCategory: (category) => {
    set((draft) => {
      draft.selectedCategory = category
    })
  },
})
