import React, { useEffect, useMemo, useState } from 'react'
import { App, Col, List, Row, Typography, Space, Divider, Spin } from 'antd'
import { useDropzone } from 'react-dropzone'
import useStore from 'hooks/useStore'
import { UploadedFile } from 'services/Tasks.slice'
import LensIcon from '@mui/icons-material/Lens'
import { useTranslation } from 'react-i18next'
import { TaskItem } from 'types/Tasks'
import { List as MUIList } from '@mui/material'
import FileItem from './FileItem'
import { InfoCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons'

type AttachmentsProps = {
  taskId: string
  task?: Partial<TaskItem>
  attachments?: UploadedFile[]
}

const { Text, Title } = Typography

const baseStyle = {
  flex: 1,
  display: 'flex',
  height: 180,
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'var(--border-color)',
  borderStyle: 'dashed',
  backgroundColor: 'var(--offset-background-color)',
  color: 'var(--text-color)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: 'var(--delegation-viewed)',
}

const acceptStyle = {
  borderColor: 'var(--delegation-accepted)',
}

const rejectStyle = {
  borderColor: 'var(--delegation-declined)',
}

const MAX_FILE_SIZE_MB = 20

const bytesToMegabytes = (bytes: number): string => {
  return (bytes / (1000 * 1000)).toFixed(1)
}

export const isFileSizeValid = (file: File, maxSizeMB: number): boolean => {
  const maxSizeBytes = maxSizeMB * (1000 * 1000) // Convert MB to bytes
  return file.size <= maxSizeBytes
}

export const isWithinStorageLimit = (
  file: File,
  currentStorageMB: number,
  storageLimitMB: number,
): boolean => {
  const fileSizeMB = file.size / (1000 * 1000) // Convert bytes to MB
  return currentStorageMB + fileSizeMB <= storageLimitMB
}

const Attachments = ({ taskId, attachments, task }: AttachmentsProps) => {
  const app = App.useApp()
  const { t } = useTranslation()

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
  const [pendingFiles, setPendingFiles] = useState<File[]>([])
  const [uploadError, setUploadError] = useState<boolean>(false)
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false)

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: true,
    maxFiles: 15,
    disabled: false,
    noDragEventsBubbling: true,
    onDrop: (files) => {
      handleUpload(files)
    },
  })

  const { postTaskFiles, deleteTaskFile, subscriptionEvents } = useStore(
    (state) => state,
  )

  const { taskAttachments, storage } = subscriptionEvents

  useEffect(() => {
    if (attachments) {
      setUploadedFiles(attachments)
    }
  }, [attachments, setUploadedFiles])

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setPendingFiles(acceptedFiles)
    }
  }, [acceptedFiles])

  const uploadedFileItems = uploadedFiles.map((file: UploadedFile) => {
    return (
      <FileItem
        key={file.id}
        file={file}
        task={task}
        handleDelete={() => handleDelete(file.id)}
      />
    )
  })

  const handleUpload = (files: File[]) => {
    setUploadInProgress(true)
    setUploadError(false)
    // Append the uploaded file(s) to the FormData object
    files.forEach((file) => {
      if (!isFileSizeValid(file, MAX_FILE_SIZE_MB)) {
        app.notification.error({
          message: `Please choose a file smaller than ${MAX_FILE_SIZE_MB} MB. The file you selected is currently ${bytesToMegabytes(
            file.size,
          )} MB.`,
        })
        setUploadInProgress(false)
        setUploadError(true)
        return
      }

      if (
        !isWithinStorageLimit(
          file,
          storage.currentCount || 0,
          storage.limit || 0,
        )
      ) {
        // Handle the error (e.g., show a message to the user)
        app.notification.error({
          message: `File size exceeds the remaining storage limit.`,
        })
        setUploadInProgress(false)
        setUploadError(true)
        return
      }
      // Create a new FormData object to send the file to the server
      const formData = new FormData()
      formData.append('task', taskId)
      formData.append('document', file)
      // Replace 'your_upload_endpoint' with the actual endpoint to which you want to send the file(s)
      postTaskFiles(formData)
        .then((data) => {
          setUploadedFiles((prev) => prev.concat(data))
          acceptedFiles.splice(0, acceptedFiles.length)
          setPendingFiles([])
        })
        .catch((error) => {
          setUploadError(true)
          // Handle any errors that occurred during the upload
          app.notification.error({
            message: error.response.data.fallback_message,
          })
        })
        .finally(() => {
          setUploadInProgress(false)
        })
    })
  }

  const handleDelete = (fileId: number) => {
    // Create a new FormData object to send the file to the server
    const formData = new FormData()

    // Append the uploaded file(s) to the FormData object
    acceptedFiles.forEach((file) => {
      formData.append('file', file)
    })

    const prevfiles = uploadedFiles

    const index = uploadedFiles.findIndex((uf) => uf.id === fileId)
    setUploadedFiles(
      uploadedFiles.slice(0, index).concat(uploadedFiles.slice(index + 1)),
    )

    // Replace 'your_upload_endpoint' with the actual endpoint to which you want to send the file(s)
    deleteTaskFile(fileId, taskId)
      .then(() => {
        app.notification.success({
          message: 'File deleted successfully',
        })
      })
      .catch((error) => {
        // Handle any errors that occurred during the upload
        app.notification.success({
          message: `Error deleting file: ${error}`,
        })
        setUploadedFiles(prevfiles)
      })
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  )

  return (
    <>
      <div className="container">
        <Row
          justify="center"
          style={{ marginTop: 24, marginLeft: 14, marginRight: 14 }}
        >
          <Col span={24} style={{ margin: '16px 0' }}>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} disabled={uploadInProgress} />
              <div> {t('attachments-tab.upload-file')}</div>
            </div>
          </Col>
        </Row>
        <Divider />
        <Col span={24} style={{ marginBottom: 28, marginInline: 14 }}>
          <List>
            <Title level={5}>{t('attachments-tab.pending-uploads')}:</Title>
            {pendingFiles?.map((file: File) => (
              <li key={file.name}>
                <Space>
                  <Text strong>{file.name}</Text> -{' '}
                  <Text type="secondary">{bytesToMegabytes(file.size)} MB</Text>
                  {uploadError ? (
                    <InfoCircleOutlined
                      style={{ color: 'var(--warning-color)' }}
                    />
                  ) : (
                    <Spin indicator={<Loading3QuartersOutlined spin />} />
                  )}
                </Space>
              </li>
            ))}
            {pendingFiles.length === 0 && (
              <li>
                <Text type="secondary">------</Text>
              </li>
            )}
          </List>
        </Col>
        {uploadedFileItems.length > 0 && (
          <Row
            justify="center"
            style={{ marginBottom: 48, marginTop: 24, marginInline: 14 }}
          >
            <Col span={24}>
              <Title level={4} style={{ marginBottom: 28 }}>
                {t('attachments-tab.file-attachments')}
              </Title>
              <MUIList sx={{ maxHeight: 260, overflow: 'auto' }}>
                {uploadedFileItems}
              </MUIList>
            </Col>
          </Row>
        )}
      </div>
      <div
        style={{
          position: 'absolute',
          justifyContent: 'center',
          bottom: 0,
          width: '100%',
          paddingBottom: 24,
          paddingTop: 24,
          marginInline: 14,
          backgroundColor: 'var(--background-color)',
        }}
      >
        {taskAttachments.title && (
          <Row>
            <Col flex="400px" style={{ marginBottom: 12 }}>
              <Space>
                <Title style={{ marginBottom: '0' }} level={5}>
                  {taskAttachments.title}:
                </Title>
                <Text>{`${taskAttachments.currentCount} of ${taskAttachments.limit}`}</Text>
              </Space>
            </Col>
            <Col flex="auto">
              <LensIcon
                style={{
                  color: `var(--licensing-${taskAttachments.status}-color)`,
                }}
              />
            </Col>
          </Row>
        )}
        {storage?.title && (
          <Row>
            <Col flex="400px" style={{ marginBottom: 12 }}>
              <Space>
                <Title style={{ marginBottom: '0' }} level={5}>
                  {storage.title}:
                </Title>
                <Text>
                  {`${storage?.currentCount || 0} MB of ${
                    storage?.limit || 0
                  } MB`}
                </Text>
              </Space>
            </Col>
            <Col flex="auto">
              <LensIcon
                style={{
                  color: `var(--licensing-${storage.status}-color)`,
                }}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  )
}

export default Attachments
