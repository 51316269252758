import { EditingState, ViewState } from '@devexpress/dx-react-scheduler'
import {
  AllDayPanel,
  Appointments,
  DayView,
  DragDropProvider,
  EditRecurrenceMenu,
  Scheduler,
} from '@devexpress/dx-react-scheduler-material-ui'
import { ThemeProvider } from '@mui/material'
import Paper from '@mui/material/Paper'
import { App, Spin } from 'antd'
import CalendarEditRoutineMenu from 'components/CalendarEditRoutineMenu'
import TimeScaleLabel from 'components/TimeScaleLabel'
import dayjs from 'dayjs'
import useStore from 'hooks/useStore'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CalendarScheduledTask, CommitChangesFunction } from 'types/Calendar'
import { ScheduledTask } from 'types/Tasks'
import { getCalendarScheduledTaskList } from 'utils/taskUtils'
import { muiTheme } from 'utils/theme'
import {
  filterMyTasks,
  preCommitChanges,
  validateCalendarChanged,
} from 'views/Calendar/CalendarUtils'
import styles from './Calendar.module.scss'
import { CalendarCardContent } from 'components/AppointmentCardContent'

const Calendar = () => {
  const app = App.useApp()
  const selectedGroups = useStore((state) => state.selectedGroups)
  const [calendarTasks, setCalendarTasks] = useState(
    [] as Array<CalendarScheduledTask>,
  )
  const today = dayjs()
  const currentHour = today.hour()
  const {
    getScheduledTasks,
    theme: currentTheme,
    editScheduledTask,
    user,
  } = useStore((state) => state)
  const theme = muiTheme(currentTheme)
  const [loading, setLoading] = useState(false)
  const { shouldUpdateTasks, setShouldUpdateTasks, includeTasksAssignedToMe } =
    useStore((state) => state)
  const { t, i18n } = useTranslation()
  const currentLocale = i18n.language.replace('_', '-')

  const updateScheduledTask = async (
    id: string,
    params: Partial<CalendarScheduledTask>,
  ) => {
    const previousValue = [...calendarTasks]
    const newCalendarTasks = calendarTasks.map((task) =>
      task.id === id ? { ...task, ...params } : task,
    )
    setCalendarTasks(newCalendarTasks)
    const success = await editScheduledTask(
      parseInt(id),
      params as unknown as Partial<ScheduledTask>,
      user?.data?.id || '',
    )
    if (!success) {
      setCalendarTasks(previousValue)
    }
  }

  const commitChanges: CommitChangesFunction = ({ changed }) => {
    if (changed) {
      const validation = validateCalendarChanged(changed, calendarTasks)
      if (!validation.isValid) {
        if (validation.validationErrorCode) {
          app.notification.warning({
            message: t(validation.validationErrorCode, { ns: 'validation' }),
          })
        }
        return
      }

      if (!validation.values) {
        return
      }
      updateScheduledTask(
        validation.values.calendarTaskId,
        validation.values.params,
      )
    }
  }

  const fetchTasks = useCallback(() => {
    setLoading(true)
    getScheduledTasks({
      groups: selectedGroups,
      delegatedToMe: includeTasksAssignedToMe,
      day: dayjs(),
      viewType: 'day',
    })
      .then((tasks) => {
        const myTasks = filterMyTasks(tasks, user?.data?.id)
        setCalendarTasks(getCalendarScheduledTaskList(myTasks, true))
      })
      .catch((error) => {
        const message = error?.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [
    app.notification,
    getScheduledTasks,
    includeTasksAssignedToMe,
    selectedGroups,
    user?.data?.id,
  ])

  useEffect(() => {
    fetchTasks()
  }, [fetchTasks, selectedGroups])

  useEffect(() => {
    if (shouldUpdateTasks) {
      fetchTasks()
      setShouldUpdateTasks(false)
    }
  }, [fetchTasks, shouldUpdateTasks, setShouldUpdateTasks])

  const i18nMessages = {
    allDay: t('calendar.all-day'),
  }

  return (
    <Spin spinning={loading} size="large">
      <div
        style={{
          width: '100%',
          maxWidth: '500px',
        }}
        className={styles.calendarContainer}
      >
        <ThemeProvider theme={theme}>
          <Paper>
            <Scheduler
              data={calendarTasks}
              height="auto"
              locale={currentLocale}
            >
              <ViewState currentDate={today.toDate()} />
              <EditingState
                onCommitChanges={commitChanges}
                preCommitChanges={preCommitChanges}
              />
              <EditRecurrenceMenu layoutComponent={CalendarEditRoutineMenu} />
              <DayView
                startDayHour={currentHour}
                timeScaleLabelComponent={TimeScaleLabel}
              />
              <AllDayPanel messages={i18nMessages} />
              <Appointments appointmentContentComponent={CalendarCardContent} />
              <DragDropProvider />
            </Scheduler>
          </Paper>
        </ThemeProvider>
      </div>
    </Spin>
  )
}

export default Calendar
