import useStore from 'hooks/useStore'
import dayjs from 'dayjs'
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui'
import { FormatterFn } from '@devexpress/dx-react-scheduler'
import { Typography, Flex } from 'antd'
import { colorList } from 'components/AccountGroupFilter/GroupForm'

const { Text } = Typography

const TimeBlockAppointmentContent = (
  props: Appointments.AppointmentContentProps,
) => {
  const { timeFormat } = useStore()
  const { groups } = useStore((state) => state.tasks)
  const { categories } = useStore((state) => state)

  const formatDate: FormatterFn = (date) => {
    return dayjs(date).format(timeFormat)
  }

  const backgroundColor = () => {
    if (props?.data?.groupCategory && props?.data?.groupCategory !== 'null') {
      const category = categories.find(
        (category) => category.id === Number(props.data.groupCategory),
      )
      return category ? category.metadata.color : colorList[0]
    }

    if (props?.data?.groupId) {
      const group = groups?.find((group) => group.id === props.data.groupId)
      return group ? group.metadata.color : colorList[0]
    }

    return colorList[0]
  }

  const renderGroups = (groups: { title: string }[]) => {
    if (groups.length < 1) {
      return `${groups.map((group) => group.title).join(', ')}`
    }
    if (props?.data?.group?.length === 1) {
      return `${props?.data?.group[0].title}`
    }
    return `${groups
      ?.slice(0, 1)
      .map((group) => group.title)
      .join(', ')} +${groups?.length - 1} more`
  }

  const title = () => {
    if (props?.data?.title) {
      return `${props?.data?.title}`
    }
    if (props?.data?.group?.length === 1) {
      return `${props?.data?.group[0].title}`
    }
    if (props?.data?.group?.length > 1) {
      return `${props?.data?.group
        ?.slice(0, 1)
        ?.map((group: { title: string }) => group.title)
        .join(', ')} + ${props?.data?.group?.length - 1} more`
    }
  }

  return (
    <Appointments.AppointmentContent
      {...props}
      formatDate={formatDate}
      style={{
        backgroundColor: backgroundColor(),
        height: '100%',
      }}
    >
      <Flex gap={1} vertical style={{ minWidth: 100 }}>
        <Text style={{ color: 'var(--white)' }} ellipsis={true}>
          {title()}
        </Text>
        {props?.data?.title && (
          <Text
            style={{
              color: 'var(--white)',
              textWrap: 'wrap',
            }}
          >
            {renderGroups(props?.data?.group)}
          </Text>
        )}
      </Flex>
    </Appointments.AppointmentContent>
  )
}
export default TimeBlockAppointmentContent
