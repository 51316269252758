import { SvgIcon } from '@mui/material'
import { Menu } from 'antd'
import { colorList, iconList } from 'components/AccountGroupFilter/GroupForm'
import Dropdown from 'components/Dropdown'
import useStore from 'hooks/useStore'
import { ElementType, useCallback, useMemo, useState } from 'react'
import styles from './GroupDropdown.module.scss'

type GroupDropdownProps = {
  onChange?: (selection?: number) => void
  outlined?: boolean
  icon: ElementType
  groupName: string
  isReadonly?: boolean
  isDisabled?: boolean
}

export const GroupDropdown = ({
  outlined,
  onChange,
  icon,
  groupName,
  isReadonly,
  isDisabled = false,
}: GroupDropdownProps) => {
  const { groups } = useStore((state) => state.tasks)
  const { user } = useStore((state) => state)
  const [isOpen, setOpen] = useState(false)
  const submitAndClose = useCallback(
    (nextValue: number) => () => {
      if (nextValue) {
        onChange?.(nextValue)
        setOpen(false)
      }
    },
    [onChange, setOpen],
  )

  const items = useMemo(
    () =>
      groups
        ?.filter((group) => {
          return (
            group.groupMembers?.find((gm) => gm.user === user?.data.id)
              ?.status !== 'inactive' && group.status !== 'archived'
          )
        })
        .map((group) => {
          return {
            key: group.id,
            icon: (
              <SvgIcon
                component={
                  iconList.find((icon) => icon.value === group.metadata.icon)
                    ?.label || iconList[0].label
                }
              />
            ),
            label: group.alternativeTitle || group.title,
            className: styles.menuItem,
            onClick: submitAndClose(group.id),
            style: {
              background: group.metadata.color || colorList[0],
              color: 'var(--white)',
            },
          }
        }),
    [groups, submitAndClose, user?.data.id],
  )

  return (
    <Dropdown
      isOpen={isOpen}
      outlined={outlined}
      onChange={(nextValue) => setOpen(nextValue)}
      icon={<SvgIcon component={icon} sx={{ color: 'white' }} />}
      title={groupName}
      isReadonly={isReadonly || isDisabled}
    >
      <Menu
        mode="vertical"
        style={{
          width: 160,
          borderInlineEnd: 'none',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 20px)',
          margin: 4,
        }}
        items={items}
      />
    </Dropdown>
  )
}

export default GroupDropdown
