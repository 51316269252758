import {
  CloseCircleOutlined,
  ImportOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import { Button, Col, Divider, Input, Popconfirm, Row, Tooltip } from 'antd'
import useStore from 'hooks/useStore'
import { produce } from 'immer'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { TaskType } from 'services/Tasks.slice'
import useSubscription from 'hooks/useSubscription'
import { SubscriptionPackage } from 'utils/subscription'
import RestrictedComponentPopover from 'components/RestrictedComponentPopover'

type SubTaskFormProps = {
  value?: Array<Partial<TaskType>>
  onChange: (e: Array<Partial<TaskType>>) => void
  disabled?: boolean
  setIsDirty: (value: boolean) => void
  importSubtasks: (excludeCheckedItems?: boolean) => void
  checkedItems: number
}

const SubTaskForm = ({
  value = [],
  onChange,
  disabled,
  setIsDirty,
  importSubtasks,
  checkedItems,
}: SubTaskFormProps) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [type, setType] = useState('Subtask')
  const [showIssues, setShowIssues] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const { user } = useStore((state) => state)
  const { subscriptionPackage } = useSubscription((state) => state)

  useEffect(() => {
    const integrationUsers =
      process.env.REACT_APP_INTEGRATION_USERS?.split(',') || []
    user && setShowIssues(integrationUsers.includes(user.data.email) || false)
  }, [user, setShowIssues])

  const add = () => {
    if (!title) return

    const subTask: Partial<TaskType> = {
      id: nanoid(5),
      title: title,
    }
    if (type === 'Issue') {
      subTask.priorityGroup = 8
    }

    const updatedSubTasks = produce(value, (draft) => {
      draft.push(subTask as TaskType)
    })

    onChange(updatedSubTasks)
    setTitle('')
    setIsDirty(false)
  }

  const remove = (indexToRemove: number) => () => {
    const updatedSubTasks = produce(value, (draft) => {
      if (indexToRemove !== -1) {
        draft.splice(indexToRemove, 1)
      }
      setIsDirty(false)
    })
    onChange(updatedSubTasks)
  }

  const validateBeforeImportingSubtasks = () => {
    if (checkedItems) {
      setIsConfirmOpen(true)
    } else {
      importSubtasks()
    }
  }

  return (
    <>
      <Row>
        <Col flex="none">
          {subscriptionPackage !== SubscriptionPackage.TEAMS_LICENSE &&
          subscriptionPackage !== SubscriptionPackage.FULL_14_DAYS_TRIAL ? (
            <RestrictedComponentPopover
              messages={[
                t('new-task-form.import-sub-tasks', { ns: 'common' }),
                t('subscription.team-license-required', { ns: 'common' }),
              ]}
            >
              <Button
                type="primary"
                shape="circle"
                icon={<ImportOutlined />}
                className="gradient"
                style={{
                  backgroundColor: '#1890ff',
                  borderColor: '#1890ff',
                  cursor: 'not-allowed',
                  marginRight: '8px',
                  marginTop: '4px',
                }}
              />
            </RestrictedComponentPopover>
          ) : (
            <Popconfirm
              title={t('new-task-form.convert-done-tasks.title')}
              description={t('new-task-form.convert-done-tasks.description')}
              open={isConfirmOpen}
              onConfirm={() => {
                setIsConfirmOpen(false)
                importSubtasks()
              }}
              onCancel={() => {
                setIsConfirmOpen(false)
                importSubtasks(true)
              }}
              okText={t('actions.yes')}
              cancelText={t('actions.no')}
            >
              <Tooltip
                title={t('new-task-form.import-sub-tasks', { ns: 'common' })}
              >
                <Button
                  onClick={validateBeforeImportingSubtasks}
                  type="primary"
                  shape="circle"
                  icon={<ImportOutlined />}
                  className="gradient"
                  style={{
                    backgroundColor: '#1890ff',
                    borderColor: '#1890ff',
                    marginRight: '8px',
                    marginTop: '4px',
                  }}
                />
              </Tooltip>
            </Popconfirm>
          )}
        </Col>
        <Col flex="auto">
          <Input
            key="query-input"
            type="text"
            value={title}
            placeholder="Add a title later..."
            onChange={(e) => {
              setTitle(e.target.value)
              if (e.target.value.trim()) {
                setIsDirty(true)
              } else {
                setIsDirty(false)
              }
            }}
            style={{ marginBottom: '16px', paddingRight: '4px' }}
            prefix={
              showIssues && (
                // Make this a dropdown that sets the type based on a mapped label
                <Button
                  onClick={() =>
                    setType(type === 'Subtask' ? 'Issue' : 'Subtask')
                  }
                >
                  {type}
                </Button>
              )
            }
            suffix={
              <Button type="dashed" onClick={add} disabled={disabled}>
                <PlusCircleOutlined />
              </Button>
            }
            onKeyDown={(evt) => {
              if (evt.key === 'Enter') {
                evt.preventDefault()
                evt.stopPropagation()

                add()

                return false
              }
            }}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Col style={{ display: 'flex', flexDirection: 'column-reverse' }}>
        {value.map((subtask, index) => (
          <Row key={subtask.id}>
            <Input
              readOnly
              type="text"
              variant="borderless"
              value={subtask.title}
              style={{
                flex: 1,
                marginRight: '6px',
                marginBottom: '0px',
                paddingRight: '4px',
              }}
              prefix={
                <Button
                  danger
                  type="text"
                  shape="circle"
                  onClick={remove(index)}
                  style={{ marginBottom: '0px' }}
                >
                  <CloseCircleOutlined />
                </Button>
              }
            />

            {value.length > 0 && index > 0 && (
              <Divider dashed style={{ margin: '5px 0px' }} />
            )}
          </Row>
        ))}
      </Col>
    </>
  )
}

export default SubTaskForm
