import React, { useEffect, useState } from 'react'
import { getAudioTaskAttachments, getFileType } from 'utils/taskUtils'
import { UploadedFile } from 'services/Tasks.slice'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Link from '@mui/material/Link'
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import VideoFileIcon from '@mui/icons-material/VideoFile'
import AudioPlayer from 'components/AudioPlayer'
import { TaskItem } from 'types/Tasks'

type FileItemProps = {
  file: UploadedFile
  handleDelete: (id: number) => void
  task?: Partial<TaskItem>
}

const FileItem = ({ file, handleDelete, task }: FileItemProps) => {
  const [audioTasks, setAudioAudioTasks] = useState<UploadedFile[]>([])
  const fileType = getFileType(file.filename)

  const hasAudioTasks = task?.inputType === 'audio' && audioTasks.length > 0

  useEffect(() => {
    if (file) {
      const audioTaskAttachments = getAudioTaskAttachments([file])
      if (audioTaskAttachments.length > 0) {
        setAudioAudioTasks([file])
      }
    }
  }, [file])

  return (
    <ListItem
      key={file.filename}
      sx={{ width: 500, pl: 0 }}
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => handleDelete(file.id)}
          disabled={hasAudioTasks}
        >
          <DeleteOutlineIcon
            sx={{ color: 'var(--text-color)', fontSize: 26 }}
          />
        </IconButton>
      }
    >
      <ListItemAvatar>
        {fileType === 'image' && (
          <img height="40px" width="40px" src={file.document} alt="preview" />
        )}
        {fileType === 'audio' && hasAudioTasks && (
          <AudioPlayer tracks={audioTasks} task={task} />
        )}
        {fileType === 'audio' && !hasAudioTasks && (
          <AudioFileOutlinedIcon fontSize="large" />
        )}
        {fileType === 'document' && (
          <DescriptionOutlinedIcon fontSize="large" />
        )}
        {fileType === 'video' && <VideoFileIcon fontSize="large" />}
        {fileType === 'other' && (
          <InsertDriveFileOutlinedIcon fontSize="large" />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          fileType === 'audio' && hasAudioTasks ? (
            <ListItemText primary={file.filename} />
          ) : (
            <Link href={file.document} target="_blank">
              {file.filename}
            </Link>
          )
        }
      />
    </ListItem>
  )
}

export default FileItem
