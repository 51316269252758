import React from 'react';
import { Alert, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useStore from 'hooks/useStore';

const VersionBanner: React.FC = () => {
  const { t } = useTranslation()
  const showVersionBanner = useStore((state) => state.showVersionBanner)
  const setShowVersionBanner = useStore((state) => state.setShowVersionBanner)

  const handleCloseApp = () => {
    if ('serviceWorker' in navigator) {
      // Unregister the current service worker
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          registration.unregister().then(() => {
            // Register the new service worker
            navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js?v=${new Date().getTime()}`)
              .then((newRegistration) => {
                newRegistration.onupdatefound = () => {
                  const installingWorker = newRegistration.installing
                  if (installingWorker == null) {
                    return
                  }
                  installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                      if (navigator.serviceWorker.controller) {
                        // Listen for the new service worker to take control
                        navigator.serviceWorker.addEventListener('controllerchange', () => {
                          // Optionally reload the page
                          setShowVersionBanner(false)
                          window.location.reload();
                        });
                        
                        // At this point, the updated precached content has been fetched,
                        // but the previous service worker will still serve the older
                        // content until all client tabs are closed.
                        if (registration.waiting) {
                          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                        }
                      } 
                    }
                  }
                }
              }).catch((error) => {
                console.error('Service worker registration failed:', error);
              });
            });
          }
      });
    }
  };

  return (
    <>
      {showVersionBanner && (
        <Alert
          severity="info"
          action={
            <Button color="inherit" onClick={handleCloseApp}>
              {t('new-version.close-app')}
            </Button>
          }
        >
          {t('new-version.description')}
        </Alert>
      )}
    </>
  );
};

export default VersionBanner;
