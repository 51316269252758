import {
  BellOutlined,
  LineChartOutlined,
  MessageOutlined,
  PaperClipOutlined,
  PlusOutlined,
  SettingOutlined,
  ContactsOutlined,
} from '@ant-design/icons'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import {
  App,
  FloatButton,
  Layout,
  Modal,
  Popconfirm,
  Spin,
  Tabs,
  TabsProps,
} from 'antd'
import RippleBadge from 'components/AudioPlayer/RippleBadge'
import ChatWindow from 'components/Chat/ChatWindow'
import Container from 'components/Container'
import useStore from 'hooks/useStore'
import useTaskEditorStore from 'hooks/useTaskEditorStore'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TaskEditorModalTabs, useTaskEditor } from 'sections/TaskEditorModal'
import { TaskMode, TaskType } from 'services/Tasks.slice'
import {
  CustomField,
  CustomFieldValue,
  EntryRequired,
} from 'types/CustomFields'
import { Status } from 'types/Status'
import {
  CustomFieldForwardRefType,
  TaskEditFormForwardRefType,
  TaskPriorityGroup,
  TaskStatusId,
} from 'types/Tasks'
import { Userpilot } from 'userpilot'
import { customEvents, publish } from 'utils/event'
import { TaskActions } from 'utils/permissions'
import AddCompanyModal from './AddCompanyModal'
import AddContactModal from './AddContactModal'
import Attachments from './Attachments/Attachments'
import CompletionReport from './CompletionReport'
import CustomFields from './CustomFields'
import { AddInviteModal } from './DelegationDrawer'
import InviteStatus from './InviteStatus'
import ModalContent from './ModalContent'
import TabCloseButton from './TabCloseButton'
import TaskEditForm from './TaskEditForm/TaskEditForm'
import styles from './TaskEditorModal.module.scss'
import TaskMembers from './TaskMembers'
import { AgendaPreview } from 'components/Agendas'
import { Segment } from 'types/Agendas'

type TaskEditorModalProps = {
  isDocked?: boolean
}

const TaskEditorModal = (props: TaskEditorModalProps) => {
  const app = App.useApp()
  const { isDocked } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const customFieldsRef = useRef<CustomFieldForwardRefType | null>(null)
  const taskEditFormRef = useRef<TaskEditFormForwardRefType | null>(null)

  const {
    isOpen,
    close,
    open,
    initialValues = {},
    isDrawerOpen,
    toggleDrawer,
    setInitialValues,
    hasUpdatePermissionOnTask,
    editorMode,
  } = useTaskEditor()

  const [isCancelTabChangesOpen, setIsCancelTabChangesOpen] =
    useState<boolean>(false)
  const [status, setStatus] = useState(Status.IDLE)
  const [subItems, setSubItems] = useState<Array<Partial<TaskType>>>([])
  const [isFlagged, setFlagged] = useState<boolean>(false)
  const [isSubItemDirty, setIsSubItemDirty] = useState<boolean>(false)
  const [isSubtaskVisible, setSubtaskVisible] = useState<boolean>(true)
  const [isAddInviteOpen, setIsAddInviteOpen] = useState<boolean>(false)
  const [isAddContactOpen, setIsAddContactOpen] = useState<boolean>(false)
  const [isAddCompanyOpen, setIsAddCompanylOpen] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<string>(
    TaskEditorModalTabs.TASK_FORM,
  )
  const [isRoutineDefinition, setIsRoutineDefinition] = useState(false)
  const [selectedChat, setSelectedChat] = useState<string>()

  const [customFields, setCustomFields] = useState<
    CustomField | null | undefined
  >()
  const [customData, setCustomData] = useState<
    CustomFieldValue | null | undefined
  >()

  const [agenda, setAgenda] = useState<Segment[]>()

  const user = useStore((state) => state.user)

  const deleteTaskById = useStore((state) => state.deleteTaskById)
  const setLastModifiedTasks = useStore((state) => state.setLastModifiedTasks)
  const setTaskLevels = useStore((state) => state.setTaskLevels)
  const {
    updateIsFloatButtonVisible,
    updateIsFloatButtonTemporarilyHidden,
    updateLeftPositionFloatingButtons,
  } = useTaskEditorStore((state) => state)

  const getAllContacts = useStore((state) => state.getAllContacts)
  const getAllGroups = useStore((state) => state.getAllGroups)
  const expirePendingInvites = useStore((state) => state.expirePendingInvites)
  const getChatByTaskID = useStore((state) => state.getChatByTaskID)
  const chats = useStore((state) => state.chats)
  const getSubscriptionStatus = useStore((state) => state.getSubscriptionStatus)
  const getCustomFieldByTaskId = useStore(
    (state) => state.getCustomFieldByTaskId,
  )
  const getCustomValueByTaskId = useStore(
    (state) => state.getCustomValueByTaskId,
  )
  const updateTask = useStore((state) => state.updateTask)

  const getAgenda = useStore((state) => state.getAgenda)

  const isUpdate =
    isDocked ||
    Object.keys(initialValues).filter((item) => item !== 'id').length > 0

  const preventEditCustomFields = !hasUpdatePermissionOnTask(
    TaskActions.CUSTOM_FIELDS,
  )

  const closeModal = useCallback(() => {
    close()
    setStatus(Status.IDLE)
    setFlagged(false)
    setSubItems([])
    setSubtaskVisible(false)
    setCustomFields(undefined)
    setCustomData(undefined)
    if (isDocked) {
      navigate('/tasks')
    }
  }, [close, isDocked, navigate])

  const handleDeleteTask = (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation()
    if (!initialValues?.id) return
    setStatus(Status.LOADING)
    if (initialValues.mode === TaskMode.TEMPLATE && !initialValues.parent?.id) {
      app.notification.info({
        message: t('task-form.template-will-be-deleted', { ns: 'validation' }),
      })
    }

    deleteTaskById(initialValues.id)
      .then(() => {
        setStatus(Status.DONE)
        setLastModifiedTasks([{ id: initialValues.id }])
        publish(customEvents.DeleteTask, initialValues)

        app.notification.success({
          message: t('new-task-form.delete-success'),
        })
      })
      .catch((error) => {
        setStatus(Status.ERROR)
        app.notification.error({
          message: error.message,
        })
      })
      .finally(() => {
        setIsSubItemDirty(false)
      })
  }

  const handleCancelDelete = (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation()
  }

  const completeTask = () => {
    if (!initialValues?.id) {
      return
    }
    const updatedProperties: TaskType = {
      status: TaskStatusId.DONE,
      completionAt: new Date().toISOString(),
      mode: TaskMode.COMPLETED,
    }
    updateTask(
      {
        taskId: initialValues?.id,
        data: updatedProperties,
      },
      user!.data.id,
    ).then(() => {
      close()
    })
  }

  const saveCustomData = (taskId: string) => {
    customFieldsRef.current?.saveData(taskId)
  }

  useEffect(() => {
    setTaskLevels()
    expirePendingInvites()
    getAllContacts()
    getAllGroups()
  }, [expirePendingInvites, getAllContacts, getAllGroups, setTaskLevels])

  useEffect(() => {
    if (initialValues?.id && isUpdate) {
      getChatByTaskID(initialValues.id)
        .then((chat) => {
          setSelectedChat(chat.id)
        })
        .catch(() => {
          setSelectedChat(undefined)
        })
    }
  }, [getChatByTaskID, initialValues.id, isUpdate])

  useEffect(() => {
    if (initialValues.id && initialValues.title) {
      const routineTaskId = initialValues?.routineTask
      const taskId = routineTaskId || initialValues.id
      if (initialValues.isCustomFieldTemplate) {
        setActiveTab(TaskEditorModalTabs.CUSTOM_FIELDS)
      }
      if (initialValues.showChatAtOpening) {
        setActiveTab(TaskEditorModalTabs.MESSAGES)
      }
      getCustomFieldByTaskId(taskId)
        .then((customFields) => {
          if (!customFields) {
            setCustomFields({ task: taskId } as CustomField)
            setCustomData(undefined)
          } else {
            setCustomFields(customFields)
            const byUserId =
              customFields.dataEntryRequired === EntryRequired.PER_USER
                ? user?.data.id
                : undefined
            getCustomValueByTaskId(initialValues.id!, byUserId)
              .then((customData) => {
                if (customData.length) {
                  setCustomData(customData[0])
                } else {
                  setCustomData({
                    customField: customFields.id,
                    routineTask: routineTaskId ? initialValues.id : undefined,
                    user: byUserId,
                  } as CustomFieldValue)
                }
              })
              .catch((error) => {
                setCustomData({
                  customField: customFields.id,
                  routineTask: routineTaskId ? initialValues.id : undefined,
                  user: byUserId,
                } as CustomFieldValue)
              })
          }
        })
        .catch((err) => {
          setCustomFields({ task: taskId } as CustomField)
          setCustomData(undefined)
        })
      return () => {
        setCustomFields(undefined)
        setCustomData(undefined)
      }
    }
  }, [
    getCustomFieldByTaskId,
    getCustomValueByTaskId,
    initialValues.id,
    initialValues.title,
    initialValues?.routineTask,
    initialValues.isCustomFieldTemplate,
    initialValues.showChatAtOpening,
    user?.data.id,
  ])

  const hasAttachments = initialValues?.taskAttachments || []
  const currentChat = useMemo(
    () => chats.find((chat) => chat.id === selectedChat),
    [chats, selectedChat],
  )

  const items: TabsProps['items'] = [
    {
      key: TaskEditorModalTabs.TASK_FORM,
      label: <EditOutlinedIcon style={{ fontSize: 24, alignSelf: 'center' }} />,
      children: (
        <TaskEditForm
          ref={taskEditFormRef}
          closeModal={closeModal}
          toggleFlagged={(value) => setFlagged(value)}
          isFlagged={isFlagged}
          subItems={subItems}
          setSubItems={(items) => setSubItems(items)}
          isSubItemDirty={isSubItemDirty}
          setIsSubItemDirty={(value) => setIsSubItemDirty(value)}
          toggleIsSubtaskVisible={(value) => setSubtaskVisible(value)}
          isSubtaskVisible={isSubtaskVisible}
          onInvite={() => setIsAddInviteOpen(true)}
          onAddCompany={() => setIsAddCompanylOpen(true)}
          onAddContact={() => setIsAddContactOpen(true)}
          handleCancelDelete={handleCancelDelete}
          handleDeleteTask={handleDeleteTask}
          isRoutineDefinition={isRoutineDefinition}
          setIsRoutineDefinition={setIsRoutineDefinition}
          isDocked={isDocked}
          customFields={customFields}
          setCustomFields={setCustomFields}
          customData={customData}
          isCustomFieldTabNotDirty={() => {
            if (!customFieldsRef.current) {
              return true
            }
            return customFieldsRef.current.isNotDirty()
          }}
          isCustomFieldFormValid={async () => {
            if (!customFieldsRef.current) {
              return true
            }
            return await customFieldsRef.current.isValidForm()
          }}
          goToCustomFieldTab={() =>
            setActiveTab(TaskEditorModalTabs.CUSTOM_FIELDS)
          }
          saveCustomData={saveCustomData}
        />
      ),
    },
    {
      key: TaskEditorModalTabs.CUSTOM_FIELDS,
      label: <PlaylistAddIcon className={styles.tabIcon} />,
      children: (
        <ModalContent>
          <CustomFields
            ref={customFieldsRef}
            customFields={customFields}
            customData={customData}
            setCustomFields={setCustomFields}
            setCustomData={setCustomData}
            isUpdate={isUpdate}
            isTemplate={initialValues.mode === TaskMode.TEMPLATE}
            taskId={initialValues.id}
            preventEditFields={preventEditCustomFields}
            preventEditDataValues={
              initialValues?.priorityGroup?.id ===
              TaskPriorityGroup.RoutineDefinition
            }
            returnToMainTab={() => setActiveTab(TaskEditorModalTabs.TASK_FORM)}
            saveAndCompleteTask={(defaultValues) =>
              taskEditFormRef?.current?.submitFormAndComplete(defaultValues)
            }
            completeTask={completeTask}
          />
        </ModalContent>
      ),
    },
    ...(initialValues?.priorityGroup?.id ===
      TaskPriorityGroup.RoutineDefinition ||
    initialValues?.routineTask ||
    customData?.customValue
      ? [
          {
            key: TaskEditorModalTabs.REPORT,
            label: <LineChartOutlined className={styles.tabIcon} />,
            children: (
              <ModalContent>
                <CompletionReport
                  taskId={initialValues?.routineTask || initialValues.id!}
                  isRoutine={
                    initialValues?.priorityGroup?.id ===
                      TaskPriorityGroup.RoutineDefinition ||
                    !!initialValues?.routineTask
                  }
                />
              </ModalContent>
            ),
          },
        ]
      : []),
    {
      key: TaskEditorModalTabs.ATTACHMENTS,
      label: (
        <RippleBadge
          color={
            initialValues.inputType === 'text'
              ? 'var(--business-category-color)'
              : 'var(--audio-task-color)'
          }
          invisible={hasAttachments.length > 0 ? false : true}
        >
          <PaperClipOutlined className={styles.tabIcon} />
        </RippleBadge>
      ),
      children: (
        <ModalContent style={{ height: 755 }}>
          <Attachments
            taskId={initialValues.id as string}
            attachments={initialValues.taskAttachments}
            task={initialValues}
          />
        </ModalContent>
      ),
    },
    ...(!isRoutineDefinition
      ? [
          {
            key: TaskEditorModalTabs.NOTIFICATIONS,
            label: <BellOutlined className={styles.tabIcon} />,

            children: (
              <ModalContent>
                <InviteStatus />
              </ModalContent>
            ),
          },
        ]
      : []),
    ...(!isRoutineDefinition
      ? [
          {
            key: TaskEditorModalTabs.MESSAGES,
            label: (
              <RippleBadge
                invisible={currentChat?.hasUnread === true ? false : true}
                color="var(--need-help-color)"
              >
                <MessageOutlined className={styles.tabIcon} />
              </RippleBadge>
            ),
            children: (
              <ModalContent>
                {currentChat && (
                  <Layout style={{ maxHeight: '75vh' }}>
                    <ChatWindow selectedChat={currentChat} />
                  </Layout>
                )}
              </ModalContent>
            ),
          },
        ]
      : []),
    {
      key: TaskEditorModalTabs.TASK_MEMBERS,
      label: <SettingOutlined className={styles.tabIcon} />,
      children: (
        <ModalContent>
          <TaskMembers task={initialValues} />
        </ModalContent>
      ),
    },
    ...(agenda && agenda.length > 0
      ? [
          {
            key: TaskEditorModalTabs.AGENDAS,
            label: <ContactsOutlined className={styles.tabIcon} />,
            children: (
              <ModalContent>
                <AgendaPreview data={agenda} />
              </ModalContent>
            ),
          },
        ]
      : []),
  ]

  useEffect(() => {
    if (initialValues?.routineTask || initialValues?.scheduledTask) {
      const taskId = initialValues?.routineTask || initialValues?.id || ''
      getAgenda(taskId).then((data) => {
        if (data) {
          setAgenda(data)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.routineTask])

  const getWidth = () => {
    if (isDrawerOpen && activeTab === TaskEditorModalTabs.TASK_FORM) {
      return '1200px'
    }
    return '1000px'
  }

  const onTabClick = async (key: string) => {
    if (key === TaskEditorModalTabs.ATTACHMENTS) {
      const formValues = taskEditFormRef?.current?.getFormValues()
      const events = ['taskAttachments', 'storage']
      if (formValues?.title && initialValues.id && isUpdate) {
        await getSubscriptionStatus(events, initialValues.id)
      }
    }

    if (key === TaskEditorModalTabs.MESSAGES && initialValues.id && isUpdate) {
      getChatByTaskID(initialValues.id)
        .then((chat) => {
          setSelectedChat(chat.id)
        })
        .catch(() => {})
    }
  }

  const onTabChange = (key: string) => {
    if (key === TaskEditorModalTabs.MESSAGES) {
      setSelectedChat(undefined)
    }
    setActiveTab(key)
  }

  const handleTabClose = () => {
    if (activeTab === TaskEditorModalTabs.MESSAGES) {
      if (!taskEditFormRef?.current?.checkIsFormDirty()) {
        closeModal()
      }
    }
    let isValid = true
    if (activeTab === TaskEditorModalTabs.CUSTOM_FIELDS) {
      isValid = customFieldsRef.current?.isNotDirty() || false
    }
    if (isValid) {
      setActiveTab(TaskEditorModalTabs.TASK_FORM)
    } else {
      setIsCancelTabChangesOpen(true)
    }
  }

  const afterClose = () => {
    if (isDrawerOpen) {
      toggleDrawer()
    }
    setSelectedChat(undefined)
    setInitialValues(undefined)
    setAgenda(undefined)
    setActiveTab(TaskEditorModalTabs.TASK_FORM)
  }

  const handleScroll = useCallback(
    (evt: Event) => {
      const CUSTOM_EVENT = 'custom_event'
      const element = document.querySelector('.ant-modal')
      if (element) {
        const rect = element.getBoundingClientRect()
        const isVisible =
          rect.bottom - window.innerHeight > 40 &&
          window.innerHeight - 300 > rect.top
        updateIsFloatButtonVisible(isVisible)
        if (evt.type !== CUSTOM_EVENT) {
          updateIsFloatButtonTemporarilyHidden(false)
        }
        const windowWidth = window.innerWidth
        const delegationX = Math.max(0, rect.x + 4)
        const taskX = Math.min(windowWidth - 350, rect.x + rect.width - 350)
        updateLeftPositionFloatingButtons(delegationX, taskX)
      }
    },
    [
      updateIsFloatButtonVisible,
      updateIsFloatButtonTemporarilyHidden,
      updateLeftPositionFloatingButtons,
    ],
  )

  useEffect(() => {
    const CUSTOM_EVENT = 'custom_event'

    const addScrollListener = () => {
      const elements = document.getElementsByClassName(
        'ant-modal-wrap ant-modal-centered',
      )

      if (elements.length) {
        elements[0].addEventListener('scroll', handleScroll, { passive: true })
        handleScroll(new Event(CUSTOM_EVENT))
      }
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          addScrollListener()
        }
      })
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
      const elements = document.getElementsByClassName(
        'ant-modal-wrap ant-modal-centered',
      )
      if (elements.length) {
        elements[0].removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  const mainTabsComponent = (
    <Tabs
      defaultActiveKey={TaskEditorModalTabs.TASK_FORM}
      items={isUpdate ? items : items.slice(0, 3)}
      tabBarExtraContent={
        <Popconfirm
          title={t('new-task-form.discard-prompt.title')}
          description={t('new-task-form.discard-prompt.description')}
          open={isCancelTabChangesOpen}
          placement="bottom"
          onConfirm={() => {
            setIsCancelTabChangesOpen(false)
            customFieldsRef.current?.cancelChanges()
            setActiveTab(TaskEditorModalTabs.TASK_FORM)
          }}
          onCancel={() => {
            setIsCancelTabChangesOpen(false)
          }}
          icon={null}
          okText={t('new-task-form.discard-prompt.ok-text')}
          cancelText={t('new-task-form.discard-prompt.cancel-text')}
        >
          <TabCloseButton
            onClick={handleTabClose}
            visible={activeTab !== TaskEditorModalTabs.TASK_FORM}
          />
        </Popconfirm>
      }
      activeKey={activeTab}
      onChange={onTabChange}
      className={styles.tab}
      onTabClick={onTabClick}
    />
  )

  return (
    <>
      <FloatButton
        className={styles.createTaskButton}
        icon={<PlusOutlined />}
        onClick={() => {
          Userpilot.track('Clicked add new task button')
          open()
        }}
        style={{ width: 65, height: 65 }}
      />
      <AddInviteModal
        isOpen={isAddInviteOpen}
        onCancel={() => setIsAddInviteOpen(false)}
        taskId={initialValues?.id}
        closeModal={() => {
          setIsAddInviteOpen(false)
        }}
      />
      <AddCompanyModal
        isOpen={isAddCompanyOpen}
        onCancel={() => setIsAddCompanylOpen(false)}
      />
      <AddContactModal
        isOpen={isAddContactOpen}
        onCancel={() => setIsAddContactOpen(false)}
      />
      {isDocked ? (
        <div style={{ width: '100%' }}> {mainTabsComponent} </div>
      ) : (
        <Modal
          zIndex={1005}
          closable={false}
          open={isOpen}
          maskClosable={false}
          onCancel={closeModal}
          width={getWidth()}
          className={styles.modal}
          footer={null}
          centered={true}
          destroyOnClose
          afterClose={afterClose}
        >
          <>
            {status === Status.LOADING ? (
              <Container
                style={{ height: editorMode === 'create' ? 300 : 800 }}
              >
                <Spin />
              </Container>
            ) : (
              mainTabsComponent
            )}
          </>
        </Modal>
      )}
    </>
  )
}

export default TaskEditorModal
